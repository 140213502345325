<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Withdrawn APPLICATIONS</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Forms"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto"> </v-col>
      </v-row>

      <v-data-table
        :headers="tableHeaders"
        :items="forms"
        hide-default-footer
        disable-pagination
        class="mt-4 mb-5"
        no-data-text="No forms found"
      >
        <template v-slot:item.property_address="{ item }">
          <div v-if="item.property_id">
            {{ item.property.full_address }}
          </div>
          <div v-else>{{ item.address_text }}</div>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                class="mr-2"
                v-on="on"
                @click="openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="green lighten-4 green--text"
                v-on="on"
                :to="{
                  name: 'module-easylets-applicationforms-individual',
                  params: { formId: item.application_id },
                }"
              >
                <v-icon x-small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <v-dialog v-model="deleteForm.dialog" persistent max-width="400">
        <v-card>
          <v-card-title class="headline">Archive Form</v-card-title>
          <v-card-text>Are you sure you wish to archive? </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="resetDelete"
              >No, cancel</v-btn
            >
            <v-btn
              color="success"
              depressed
              :loading="deleteForm.loading"
              @click="saveDelete"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      searchTerm: "",
      deleteForm: {
        dialog: false,
        form: {},
        loading: false,
      },
      breadcrumbs: [
        {
          text: "Application Forms",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Property", value: "property_address" },
        { text: "Group", value: "group.lead.full_name" },
        { text: "Name", value: "customer.full_name" },
        { text: "Status", value: "form_status" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    forms() {
      let forms =
        this.$store.getters["easylets/applicationformsStore/archived"];

      if (this.searchTerm !== "") {
        forms = forms.filter((c) => {
          const propertyName = c.property
            ? c.property.full_address.toLowerCase()
            : c.address_text.toLowerCase();
          const lead =
            c.group !== null ? c.group.lead.full_name.toLowerCase() : "";
          const customer = c.customer.full_name.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return (
            propertyName.includes(searchTerm) ||
            customer.includes(searchTerm) ||
            lead.includes(searchTerm)
          );
        });
      }

      return forms;
    },
  },

  methods: {
    openDelete(form) {
      this.deleteForm.form = form;
      this.deleteForm.dialog = true;
    },

    resetDelete() {
      this.deleteForm.dialog = false;
      this.deleteForm.form = {};
      this.deleteForm.loading = false;
    },

    saveDelete() {
      this.deleteForm.loading = true;

      this.$store
        .dispatch("easylets/applicationformsStore/deleteForm", {
          appId: this.$route.params.id,
          formId: this.deleteForm.form.application_id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteForm.loading = false;
        });
    },
  },
};
</script>
